import {Button, Card, CardContent, CardHeader, Container, Grid, Modal, Typography,} from "@mui/material";
import {PropsWithChildren} from "react";
import {Box, Theme} from "@mui/system";
import {makeStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../../constants/colors";

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    marginLeft: "auto",
    marginRight: "auto",
    height: "100vh",
    overflow: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${Colors.grey200}`,
    paddingBottom: 10,
    paddingRight: 0,
  },
  btnClose:{
    marginRight: -15,
  },
  card: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
    },
  },
}));

type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title?: string;
}>;

const ModalApp = ({ open, onClose, children, title }: Props) => {
  const classes = useStyles();
  const renderTitle = () => {
    return (
      <Box className={classes.header} component="div">
        <Typography variant="h5">{title}</Typography>
        <Button onClick={onClose} variant="text" className={classes.btnClose}> 
          <CloseIcon />
        </Button>
      </Box>
    );
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container fixed>
        <Grid container height="100vh" overflow={"auto"}>
          <Grid item xs={12} sm={12} md={8} lg={8} className={classes.modal}>
            <Card className={classes.card}>
              <CardHeader title={renderTitle()} />
              <CardContent>{children}</CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ModalApp;
