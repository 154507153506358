import {useState} from "react";
import {Formulario} from "../../components/Formulario";
import {IJsonFromField, TypeFieldForm,} from "../../components/InputFormulario/types";
import ModalApp from "../../components/ModalApp";
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import {Usuario} from "../../models/Usuario";

type Props = {
  open: boolean;
  onClose: () => void;
  user?: Usuario;
  onSave: (user: Usuario) => void;
};

const ModalFormUser = ({ open, onClose, user, onSave }: Props) => {
  const [tipoUser, setTipoUser] = useState(user?.tipo);
  const [loading, setLoading] = useState(false);
  const { getAll, post, put } = useApi();
  const { user: currentUser } = useUser();

  const choicesTipo = [
    { value: "COMPANY_ADMIN", label: "Company Administrator" },
    { value: "MANOBRISTA", label: "Valet parking" },
    { value: "AGENTE", label: "Agent" },
    { value: "LAVADOR", label: "Washer" },
  ];

  if (currentUser?.tipo === "ADMIN") {
    choicesTipo.push({ value: "ADMIN", label: "Mega Valet App Administrator" });
  }

  const getCompaniesList = async () => {
    if (currentUser?.tipo === "ADMIN") {
      return getAll("/companies").then((x) =>
        x.map((c: any) => ({
          value: c.id,
          label: c.name,
        }))
      );
    } else {
      return Promise.resolve(user?.companies_details || []);
    }
  };

  const formulario: IJsonFromField[] = [
    {
      name: "first_name",
      type: TypeFieldForm.TEXT,
      label: "First Name",
      valueDefault: user?.first_name,
      style: {
        backgroundColor: "transparent",
      },
      validators: {
        required: {
          value: true,
          message: "Name is required",
        },
        maxLength: 40,
      },
    },
    {
      name: "last_name",
      type: TypeFieldForm.TEXT,
      label: "Last Name",
      valueDefault: user?.last_name,
      style: {
        backgroundColor: "transparent",
      },
      validators: {
        maxLength: 40,
      },
    },
    {
      name: "email",
      type: TypeFieldForm.EMAIL,
      label: "E-mail",
      valueDefault: user?.email,
      style: {
        backgroundColor: "transparent",
      },
      validators: {
        required: {
          value: true,
          message: "Email is required",
        },
      },
    },
    {
      name: "tipo",
      type: TypeFieldForm.SELECT,
      label: "Type",
      valueDefault: user?.tipo
        ? choicesTipo.find((x) => x.value === user?.tipo)
        : undefined,
      choices: choicesTipo,
      onChange: (value) => setTipoUser(value),
    },
    {
      name: "companies",
      type: TypeFieldForm.MULTI_SELECT,
      label: "Companies",
      choices: getCompaniesList,
      disabled: () => tipoUser === "ADMIN",
      valueDefault: user?.companies_details?.map((x) => ({
        value: x.id,
        label: x.name,
      })),
    },
  ];

  if (!user?.id) {
    formulario.push(
      {
        name: "nova_senha",
        type: TypeFieldForm.PASSWORD,
        label: "Password",
        style: {
          backgroundColor: "transparent",
        },
        validators: {
          required: {
            value: true,
            message: "It is required",
          },
        },
      },
      {
        name: "confirme_senha",
        type: TypeFieldForm.PASSWORD,
        label: "Confirm Password",
        style: {
          backgroundColor: "transparent",
        },
        validators: {
          required: {
            value: true,
            message: "It is required",
          },
        },
      }
    );
  }

  const handleSubmit = (data: any, errosCallback?: (erros: any) => void) => {
    setLoading(true);
    const isAdmin = data.tipo === "ADMIN";
    if (user?.id) {
      put("/usuario", String(user?.id), {
        ...data,
        companies: isAdmin ? [] : data.companies,
      }).then(
        async (ret) => {
          onSave(ret);
          onClose();
        },
        (e) => {
          setLoading(false);
          if (e.status === 400 && errosCallback) {
            errosCallback(e.data);
          }
        }
      );
    } else {
      post("/usuario", {
        ...data,
        companies: isAdmin ? [] : data.companies,
      }).then(
        async (ret) => {
          onSave(ret);
          onClose();
        },
        (e) => {
          setLoading(false);
          if (e.status === 400 && errosCallback) {
            errosCallback(e.data);
          }
        }
      );
    }
  };

  return (
    <ModalApp
      open={open}
      onClose={onClose}
      title={user ? "Edit User" : "Add User"}
    >
      <Formulario
        name={"Filtres"}
        loading={loading}
        fields={formulario}
        onSubmit={handleSubmit}
        textSubmitButton={"apply"}
      />
    </ModalApp>
  );
};

export default ModalFormUser;
