import {useContext, useEffect, useState} from "react";
import CompanyContext from "../../contexts/CompanyContext";
import LoginContext from "../../contexts/LoginContext";
import useApi from "../useApi";

const useUser = () => {
  const { getAll } = useApi();
  const { user, setUser } = useContext(LoginContext);
  const { setCompany, setCompanies } = useContext(CompanyContext);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized && user) {
      setInitialized(true);
      if (user?.tipo === "ADMIN") {
        getAll("/companies").then((x) => {
          if (x && x.length > 0) {
            setCompany(x[0]);
          }
          setCompanies(x);
        });
      } else if (user?.companies_details && user.companies_details.length > 0) {
        setCompany(user.companies_details[0]);
        setCompanies(user.companies_details);
      }
    }
  }, [getAll, setCompanies, setCompany, user, initialized]);

  return {
    user,
    setUser,
  };
};

export default useUser;
