import {Grid, Theme} from "@mui/material";
import {PropsWithChildren} from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  pageContainer: {
    padding: theme.spacing(20, 5, 5, 5),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
}));

export default function PageContainer(props: PropsWithChildren<{}>) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item className={classes.pageContainer}>
        {props.children}
      </Grid>
    </Grid>
  );
}
