import {ApiEvent} from "../../app-events";
import * as queryString from 'query-string';

export enum ApiEventsType {
    HTTP_ERROR = 'httpError',
    FETCH_ERROR = 'fetchError',
}

const useApi = () => {

    const urlBase = "/api";

    const getCookie = (name: string) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const checkFetchResponse = (response: Promise<Response>) => {
        return response.then((response) => {
            if (response.status >= 200 && response.status <= 399) {
                if (response.status === 204) {
                    return {};
                }
                return response.json();
            } else {
                ApiEvent.emit(ApiEventsType.HTTP_ERROR, {
                    status: response.status,
                    error: response.statusText
                });
                return new Promise((res, rej) => {
                    response.json().then(data => {
                        rej({
                            status: response.status,
                            data: data,
                            error: response.statusText
                        })
                    }, e => {
                        rej({
                            status: 0,
                            error: e
                        });
                    })
                })
            }
        }, (e) => {
            ApiEvent.emit(ApiEventsType.FETCH_ERROR, e);
            return {
                error: e,
                status: 0
            }
        })
    };

    const compileUrl = (partialUrl: string, id?: string, params?: { [name: string]: any } | queryString.ParsedQuery) => {
        let url = urlBase + partialUrl;
        if (id) {
            if (url.endsWith('/')) {
                url += id;
            } else {
                url += '/' + id;
            }
        }
        url = url.endsWith('/') ? url : url + '/';
        if (params) {
            url += '?' + queryString.stringify(params);
        }
        return url
    }

    const checkFetchBlobResponse = (response: Promise<Response>) => {
        return response.then((response) => {
            if (response.status >= 200 && response.status <= 399) {
                if (response.status === 204) {
                    return Promise.resolve(new Blob());
                }
                return response.blob();
            } else {
                ApiEvent.emit(ApiEventsType.HTTP_ERROR, {
                    status: response.status,
                    error: response.statusText
                });
                return new Promise((res, rej) => {
                    response.json().then(data => {
                        rej({
                            status: response.status,
                            data: data,
                            error: response.statusText
                        })
                    }, e => {
                        rej({
                            status: 0,
                            error: e
                        });
                    })
                })
            }
        }, (e) => {
            ApiEvent.emit(ApiEventsType.FETCH_ERROR, e);
            return {
                error: e,
                status: 0
            }
        })
    };

    const getBlob = (partialUrl: string, id?: string, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> => {
        const url = compileUrl(partialUrl, id, params);
        return checkFetchBlobResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    const get = (partialUrl: string, id?: string, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> => {
        const url = compileUrl(partialUrl, id, params);
        return checkFetchResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    const getAll = (partialUrl: string, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> => {
        const url = compileUrl(partialUrl, undefined, params);
        return checkFetchResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    const post = (partialUrl: string, data: any, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> => {
        const url = compileUrl(partialUrl, undefined, params);
        return checkFetchResponse(fetch(url.toString(), {
            method: 'POST',
            headers: data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    const put = (partialUrl: string, id: string, data: any, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> => {
        const url = compileUrl(partialUrl, id, params);
        return checkFetchResponse(fetch(url.toString(), {
            method: 'PUT',
            headers: data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    const patch = (partialUrl: string, id: string, data: any): Promise<any> => {
        const url = compileUrl(partialUrl, id);
        return checkFetchResponse(fetch(url.toString(), {
            method: 'PATCH',
            headers: data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    const deleteItem = (partialUrl: string, id: string, data?: any) => {
        const url = compileUrl(partialUrl, id);
        return checkFetchResponse(fetch(url.toString(), {
            method: 'DELETE',
            headers: data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    return {
        get,
        getAll,
        post,
        getBlob,
        put,
        deleteItem,
        patch,
    }
}

export default useApi;