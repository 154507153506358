import Colors from "../../constants/colors";
import {Button, styled, TableCell, tableCellClasses, TableRow,} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: 5,
  paddingBottom: 5,
  height: "40px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ButtonStatus = styled(Button)(({ theme }) => ({
  color: Colors.grey100,
  borderRadius: 10,
  paddingLeft: 20,
  paddingRight: 20,
  width: "100%",
  opacity: 0.9,
  fontSize: 12,
}));
