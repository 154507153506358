import React, {PropsWithChildren, useState} from "react";
import {Usuario} from "../../models/Usuario";

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore
type LoginContextType = {
  user?: Usuario;
  setUser: (user?: Usuario) => void;
};

const LoginContext = React.createContext<LoginContextType>({
  setUser: () => {},
});

export default LoginContext;

export function LoginProvider({ children }: PropsWithChildren<any>) {
  const [user, setUser] = useState<Usuario>();
  return (
    <LoginContext.Provider value={{ user, setUser }}>
      {children}
    </LoginContext.Provider>
  );
}
