import {ICarWash} from "../../models/CarWash";
import {Usuario} from "../../models/Usuario";
import moment from "moment";
import {Box} from "@mui/system";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/Edit";
import Colors from "../../constants/colors";
import ModalApp from "../ModalApp";
import {useContext, useState} from "react";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {ButtonStatus, StyledTableCell, StyledTableRow,} from "../StyledCompnents";
import {LocalData} from "../LocalData";
import {Formulario} from "../Formulario";
import {TypeFieldForm} from "../InputFormulario/types";
import {Doughnut} from "react-chartjs-2";
import CompanyContext from "../../contexts/CompanyContext";
import Numero from "../Numero";


type Props = {
  washes: ICarWash[];
  usuario?: Usuario;
  onEdit?: (car: ICarWash) => void;
  saveCar: (car: ICarWash) => void;
  dashboard?: any;
};

const STATUS_COLORS = {
  Washed: "green",
  "Not Washed": Colors.grey600,
  Queued: "#6885EB",
  "Not in Carwash": Colors.grey600,
  "Wash in process": "#FAD000",
} as any;

const PRIORITY_COLORS = {
  "Wash and Stage": "green",
  "Customer is Waiting": "red",
  "Pull up to Driveway": "blue",
  "Park In Customer Parking": Colors.grey600,
} as any;
export function CarWashTable({
  usuario,
  washes,
  saveCar,
  onEdit,
  dashboard,
}: Props) {
  const [modalValet, setModalValet] = useState<ICarWash>();
  const [modalIssue, setModalIssue] = useState<ICarWash>();
  const { company } = useContext(CompanyContext);

  const dispatch = (car: ICarWash) => {
    saveCar({
      ...car,
      dispatched: true,
    });
  };

  const acceptValet = (car: ICarWash) => {
    saveCar({
      ...car,
      valet_accepted: true,
    });
  };

  const handedOver = (car: ICarWash) => {
    setModalValet(car);
  };

  const getTodayWashParams = () => {
    const late =
      Math.round(
        ((100 * (dashboard.washes.total - dashboard.washes.on_time)) /
          dashboard.washes.total) *
          100
      ) / 100;
    const onTime =
      Math.round(
        ((100 * dashboard.washes.on_time) / dashboard.washes.total) * 100
      ) / 100;
    return {
      data: {
        labels: [`Late wash - ${late}%`, `Washed on time - ${onTime}%`],
        datasets: [
          {
            label: "#",
            data: [
              dashboard.washes.total - dashboard.washes.on_time,
              dashboard.washes.on_time,
            ],
            backgroundColor: ["#C0504D", "#4F81BD"],
            borderColor: ["#C0504D", "#4F81BD"],
            borderWidth: 1,
          },
        ],
      },
    };
  };

  const getOnTimeParams = () => {
    const minutes = company?.delivery_minutes;
    const total =
      dashboard.delivery_on_time.delayed + dashboard.delivery_on_time.on_time;
    const delayed =
      Math.round(((100 * dashboard.delivery_on_time.delayed) / total) * 100) /
      100;
    const onTime =
      Math.round(((100 * dashboard.delivery_on_time.on_time) / total) * 100) /
      100;
    return {
      data: {
        labels: [
          `Valet done > ${minutes} Minutes - ${delayed}%`,
          `Valet done < ${minutes} Minutes - ${onTime}%`,
        ],
        datasets: [
          {
            label: "#",
            data: [
              dashboard.delivery_on_time.delayed,
              dashboard.delivery_on_time.on_time,
            ],
            backgroundColor: ["#C0504D", "#4F81BD"],
            borderColor: ["#C0504D", "#4F81BD"],
            borderWidth: 1,
          },
        ],
      },
    };
  };

  const getQualityParams = () => {
    const total = dashboard.washes.total;
    const issueNotes =
      Math.round(((100 * dashboard.issue_notes) / total) * 100) / 100;
    const ok =
      Math.round(
        ((100 * (dashboard.washes.total - dashboard.issue_notes)) / total) * 100
      ) / 100;
    return {
      data: {
        labels: [`Quality Issue - ${issueNotes}%`, `Quality wash ok - ${ok}%`],
        datasets: [
          {
            label: "#",
            data: [
              dashboard.issue_notes,
              dashboard.washes.total - dashboard.issue_notes,
            ],
            backgroundColor: ["#C0504D", "#4F81BD"],
            borderColor: ["#C0504D", "#4F81BD"],
            borderWidth: 1,
          },
        ],
      },
    };
  };

  const renderCharts = () => {
    return (
      <TableCell
        style={{
          maxWidth: "100px",
          width: "15%",
          borderRight: "1px solid #ccc",
        }}
        rowSpan={(washes.length || 1) + 1}
      >
        {dashboard && (
          <>
            <Doughnut {...getTodayWashParams()} />
            <Typography align="center">
              Today's Wash - {dashboard.washes.total} washes
            </Typography>
            <br />
            <Doughnut {...getOnTimeParams()} />
            <Typography align="center">
              Valet Delivery Avg -{" "}
              <Numero
                style={{ display: "inline" }}
                numero={dashboard.delivery_on_time.avg}
                minimumFractionDigits={2}
              />{" "}
              /min
            </Typography>
            <br />
            <Doughnut {...getQualityParams()} />
            <Typography align="center">Carwash Quality</Typography>
          </>
        )}
      </TableCell>
    );
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Dashboard</StyledTableCell>
              <StyledTableCell>Tag</StyledTableCell>
              <StyledTableCell>Vehicle</StyledTableCell>
              <StyledTableCell>Prom. Time</StyledTableCell>
              <StyledTableCell>Note</StyledTableCell>
              {usuario?.tipo !== "LAVADOR" && (
                <StyledTableCell style={{ width: "300px" }}>
                  Priority
                </StyledTableCell>
              )}
              <StyledTableCell style={{ width: "200px" }}>
                Status
              </StyledTableCell>
              <StyledTableCell style={{ width: "200px" }}>
                Valet
              </StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {washes.length === 0 && (
              <StyledTableRow>
                {renderCharts()}
                <StyledTableCell
                  colSpan={8}
                  style={{ verticalAlign: "top", textAlign: "center" }}
                >
                  <br />
                  <Typography>No data, check filters</Typography>
                </StyledTableCell>
              </StyledTableRow>
            )}
            {washes.length > 0 &&
              washes.map((carWash, index) => {
                const color = Colors.grey600;
                const urgente =
                  moment
                    .duration(moment(carWash.prom_time).diff(new Date()))
                    .asMinutes() <= 30;
                const colorPriority = PRIORITY_COLORS[carWash.priority];
                const colorStatus = STATUS_COLORS[carWash.status];
                return (
                  <StyledTableRow
                    key={carWash.id}
                    style={{ verticalAlign: "top" }}
                  >
                    {index === 0 && renderCharts()}
                    <StyledTableCell
                      style={{
                        alignContent: "center",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <InfoOutlinedIcon
                          style={{
                            color: urgente ? "red" : color,
                          }}
                        />

                        <Typography
                          style={{
                            marginLeft: 5,
                            fontWeight: "bold",
                          }}
                        >
                          #{carWash.tag}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {carWash.vehicle || "-"} {carWash.color || ""}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(carWash.prom_time).format("h:mm a")}
                    </StyledTableCell>
                    <StyledTableCell>{carWash.note || "-"}</StyledTableCell>
                    {usuario?.tipo !== "LAVADOR" && (
                      <StyledTableCell style={{ textAlign: "right" }}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <>
                              <ButtonStatus
                                variant="contained"
                                {...bindTrigger(popupState)}
                                style={{
                                  backgroundColor: colorPriority,
                                }}
                              >
                                {carWash.priority}
                              </ButtonStatus>
                              <Menu {...bindMenu(popupState)}>
                                {Object.keys(PRIORITY_COLORS).map((x: any) => {
                                  return (
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close();
                                        saveCar({
                                          ...carWash,
                                          priority: x,
                                        });
                                      }}
                                      key={x}
                                    >
                                      {x}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </StyledTableCell>
                    )}
                    <StyledTableCell style={{ textAlign: "right" }}>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <>
                            <ButtonStatus
                              variant="contained"
                              {...bindTrigger(popupState)}
                              style={{
                                backgroundColor: colorStatus,
                              }}
                            >
                              {carWash.status}
                            </ButtonStatus>
                            {usuario?.tipo !== "MANOBRISTA" && (
                              <Menu {...bindMenu(popupState)}>
                                {Object.keys(STATUS_COLORS).map((x: any) => {
                                  return (
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close();
                                        saveCar({
                                          ...carWash,
                                          status: x,
                                        });
                                      }}
                                      key={x}
                                    >
                                      {x}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            )}
                          </>
                        )}
                      </PopupState>
                    </StyledTableCell>
                    {usuario?.tipo === "AGENTE" ||
                    usuario?.tipo === "ADMIN" ||
                    usuario?.tipo === "COMPANY_ADMIN" ? (
                      <StyledTableCell style={{ textAlign: "right" }}>
                        {!carWash.dispatched && (
                          <Button
                            variant="contained"
                            onClick={() => dispatch(carWash)}
                          >
                            Dispatch Valet
                          </Button>
                        )}
                        {carWash.dispatched && !carWash.valet_accepted && (
                          <Typography>Waiting for valet</Typography>
                        )}
                        {carWash.dispatched && carWash.valet_accepted && (
                          <Typography>
                            Valet Accepted by{" "}
                            {carWash.user_valet_detail?.first_name} at{" "}
                            <LocalData
                              data={carWash.valet_accepted_at}
                              onlyTime
                              withoutTypography
                            />{" "}
                            {carWash.delivered_at ? (
                              <>
                                and delivered at{" "}
                                <LocalData
                                  data={carWash.delivered_at}
                                  onlyTime
                                  withoutTypography
                                />{" "}
                                {Boolean(carWash.issue_notes) && (
                                  <Button
                                    variant="text"
                                    color="warning"
                                    onClick={() => setModalIssue(carWash)}
                                  >
                                    issues notes
                                  </Button>
                                )}
                              </>
                            ) : (
                              "and waiting for handing over"
                            )}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell style={{ textAlign: "right" }}>
                        {!carWash.dispatched && (
                          <Typography>
                            It has not been dispatched yet
                          </Typography>
                        )}
                        {carWash.dispatched &&
                          !carWash.valet_accepted &&
                          usuario?.tipo !== "MANOBRISTA" && (
                            <Typography>Waiting for valet</Typography>
                          )}
                        {carWash.dispatched &&
                          !carWash.valet_accepted &&
                          usuario?.tipo === "MANOBRISTA" && (
                            <Button
                              variant="contained"
                              onClick={() => acceptValet(carWash)}
                            >
                              Hand Over
                            </Button>
                          )}
                        {carWash.dispatched && carWash.valet_accepted && (
                          <>
                            {usuario?.id === carWash.user_valet ? (
                              <Button
                                variant="contained"
                                onClick={() => handedOver(carWash)}
                              >
                                Handed over
                              </Button>
                            ) : (
                              <Typography>
                                Valet Accepted by{" "}
                                {carWash.user_valet_detail?.first_name} at{" "}
                                <LocalData
                                  data={carWash.valet_accepted_at}
                                  onlyTime
                                  withoutTypography
                                />{" "}
                                {carWash.delivered_at ? (
                                  <>
                                    and delivered at{" "}
                                    <LocalData
                                      data={carWash.delivered_at}
                                      onlyTime
                                      withoutTypography
                                    />{" "}
                                    {Boolean(carWash.issue_notes) && (
                                      <Button
                                        variant="text"
                                        color="warning"
                                        onClick={() => setModalIssue(carWash)}
                                      >
                                        issues notes
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  "and waiting for handing over"
                                )}
                              </Typography>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      <IconButton
                        color={"primary"}
                        onClick={() => onEdit?.(carWash)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            <StyledTableRow style={{ height: "100%" }} />
          </TableBody>
        </Table>
      </TableContainer>
      <ModalApp
        open={!!modalValet}
        onClose={() => setModalValet(undefined)}
        title={"Inspect from issues"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formulario
          name={"FormCarWash"}
          textSubmitButton="Handed over"
          fields={[
            {
              type: TypeFieldForm.TEXTAREA,
              name: "issue_notes",
              label: "Is there any issue notes?",
            },
          ]}
          onSubmit={(data) => {
            saveCar({
              ...modalValet,
              ...data,
              delivered: true,
            });
            setModalValet(undefined);
          }}
        />
      </ModalApp>
      <ModalApp
        open={!!modalIssue}
        onClose={() => setModalIssue(undefined)}
        title={"Issues Notes"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Typography>{modalIssue?.issue_notes}</Typography>
      </ModalApp>
    </>
  );
}
