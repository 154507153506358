import React, {CSSProperties} from 'react';
import Typography from '@mui/material/Typography';

export interface NumeroProps {
    numero: number,
    mascara?: 'NUMERO' | 'MOEDA' | 'PERCENTUAL' | 'DISTANCIA',
    style?: CSSProperties
    minimumFractionDigits?: number,
    className?: string
}

const tratarDistancia = (numero: number) => {
    if (numero < 1) {
        return `${(numero * 1000).toLocaleString('pt-br', {maximumFractionDigits: 0})} m`
    } else {
        return `${(numero).toLocaleString('pt-br', {maximumFractionDigits: 0})} km`
    }
}

export const aplicarMascara = (valor: number, mascara: 'NUMERO' | 'MOEDA' | 'PERCENTUAL' | 'DISTANCIA' = 'NUMERO', minimumFractionDigits?: number) => {

    const numero = Number(valor);
    if (!numero && numero !== 0) {
        return valor
    }
    switch (mascara) {
        case 'NUMERO':
            return numero.toLocaleString('pt-br', {minimumFractionDigits: minimumFractionDigits || 0});
        case 'MOEDA':
            return numero.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: minimumFractionDigits || 0
            });
        case 'PERCENTUAL':
            return `${(numero * 100).toLocaleString('pt-br', {minimumFractionDigits: minimumFractionDigits || 2})}%`;
        case 'DISTANCIA':
            return tratarDistancia(numero);
        default:
            return valor;
    }
};

export default function Numero(props: NumeroProps) {
    return <Typography style={props.style} className={props.className}>
        {aplicarMascara(props.numero, props.mascara, props.minimumFractionDigits)}
    </Typography>;
};
