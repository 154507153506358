import {CssBaseline} from "@mui/material";
import React from "react";
import "./index.css";
import theme from "./theme";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MomentUtils from "@date-io/moment";
import {NotificationContainer} from "./components/NotificationContainer";
import Pages from "./pages";
import {HashRouter, Route} from "react-router-dom";
import {LoginProvider} from "./contexts/LoginContext";
import {LocalizationProvider} from "@mui/lab";
import {CompanyProvider} from "./contexts/CompanyContext";
import {ArcElement, Chart, Legend, Tooltip} from 'chart.js'

Chart.register(ArcElement, Tooltip, Legend);

function App() {
  return (
    <React.Fragment>
      <LoginProvider>
        <CompanyProvider>
          <ThemeProvider theme={createTheme(theme)}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <CssBaseline />
              <NotificationContainer />
              <HashRouter>
                <Route path={``} component={Pages} />
              </HashRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </CompanyProvider>
      </LoginProvider>
    </React.Fragment>
  );
}

export default App;
