import {Add, Delete, Edit} from "@mui/icons-material";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import {StyledTableCell, StyledTableRow,} from "../../components/StyledCompnents";
import useApi from "../../hooks/useApi";
import {Usuario} from "../../models/Usuario";
import ModalFormUser from "./ModalFormUser";
import PageContainer from "../../components/PageContainer";

export default function UsersPage(props: any) {
  const [users, setUsers] = useState<Usuario[]>();
  const [showModalForm, setShowModalForm] = useState(false);
  const [userForEdit, setUserForEdit] = useState<Usuario>();
  const { getAll, deleteItem } = useApi();

  useEffect(() => {
    getAll("/usuario").then(setUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removerUser = (user: Usuario) => {
    if (
      window.confirm(`Are you sure you want to remove the user ${user.email}?`)
    ) {
      deleteItem("/usuario", String(user?.id));
      setUsers((x) => {
        const list = [...(x || [])];
        const idx = list.findIndex((y) => y.id === user.id);
        if (idx > -1) {
          list.splice(idx, 1);
        }
        return list;
      });
    }
  };

  return (
    <>
      <PageContainer>
        <Card>
          <CardHeader title={"Mega Valet Users"} />
          <CardContent>
            <Button
              variant="contained"
              onClick={() => {
                setUserForEdit(undefined);
                setShowModalForm(true);
              }}
            >
              <Add />
              New User
            </Button>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Company</StyledTableCell>
                    <StyledTableCell width={100}>Edit</StyledTableCell>
                    <StyledTableCell width={100}>Remove</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user) => (
                    <StyledTableRow key={user.id}>
                      <StyledTableCell>
                        {user.first_name} {user.last_name}
                      </StyledTableCell>
                      <StyledTableCell>{user.email}</StyledTableCell>
                      <StyledTableCell>{user.tipo}</StyledTableCell>
                      <StyledTableCell>
                        {user.companies_details?.map((x) => x.name)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={() => {
                            setUserForEdit(user);
                            setShowModalForm(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={() => {
                            removerUser(user);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </PageContainer>
      <ModalFormUser
        onClose={() => setShowModalForm(false)}
        onSave={(ret) =>
          setUsers((x) => {
            const list = [...(x || [])];
            const idx = list.findIndex((y) => y.id === ret.id);
            if (idx > -1) {
              list[idx] = ret;
            } else {
              list.push(ret);
            }
            return list;
          })
        }
        open={showModalForm}
        user={userForEdit}
      />
    </>
  );
}
