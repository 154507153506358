import React, {PropsWithChildren, useState} from "react";
import {Company} from "../../models/Company";

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore
type CompanyContextType = {
  company?: Company;
  setCompany: (company?: Company) => void;
  companies?: Company[];
  setCompanies: (companies: Company[]) => void;
};

const CompanyContext = React.createContext<CompanyContextType>({
  setCompany: () => {},
  setCompanies: () => {},
});

export default CompanyContext;

export function CompanyProvider({ children }: PropsWithChildren<any>) {
  const [company, setCompany] = useState<Company>();
  const [companies, setCompanies] = useState<Company[]>([]);
  return (
    <CompanyContext.Provider value={{ company, setCompany, companies, setCompanies }}>
      {children}
    </CompanyContext.Provider>
  );
}
