import React from "react";
import moment from "moment";
import Typography from "@mui/material/Typography";

export function LocalData(props: {
  data?: Date | string;
  onlyTime?: boolean;
  withoutTypography?: boolean;
}) {
  if (!props.data) return null;
  const TP = props.withoutTypography ? React.Fragment : Typography;
  if (props.data instanceof Date) {
    return (
      <TP>
        {props.onlyTime
          ? props.data.toLocaleTimeString()
          : props.data.toLocaleString()}
      </TP>
    );
  } else {
    const date = moment(props.data).toDate();
    return (
      <TP>
        {props.onlyTime ? date.toLocaleTimeString() : date.toLocaleString()}
      </TP>
    );
  }
}
