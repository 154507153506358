import {ThemeOptions} from "@mui/material";

const Fonts = {
    PRIMARY: 'Readex Pro',
};

const defaultTheme: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    spacing: 4,
    direction: 'ltr',
    palette: {
        common: {
            black: '#000',
            white: '#fff',
        },
        primary: {
            main: '#15386A',
            dark: '#46237a',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: Fonts.PRIMARY,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightBold: 600,
    },
};
export default defaultTheme;