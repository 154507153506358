import {Add, Close, Delete, DeleteOutline, Edit, Save} from "@mui/icons-material";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Input,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import {Company, ItemCheckListConfig} from "../../models/Company";
import ModalFormCompany from "./ModalFormCompany";
import PageContainer from "../../components/PageContainer";
import useUser from "../../hooks/useUser";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputLabel from "@mui/material/InputLabel";

export default function CompaniesPage(props: any) {
    const [companies, setCompanies] = useState<Company[]>();
    const [company, setCompany] = useState<Company>();
    const [showModalForm, setShowModalForm] = useState(false);
    const [companyForEdit, setCompanyForEdit] = useState<Company>();
    const [itemCheckListEdit, setItemCheckListEdit] = useState<ItemCheckListConfig>();
    const [itemsCheckList, setItemsCheckList] = useState<ItemCheckListConfig[]>([]);
    const {getAll, deleteItem, post, put} = useApi();
    const {user} = useUser();

    useEffect(() => {
        if (!companies) {
            if (user?.tipo === "ADMIN") {
                getAll("/companies").then(setCompanies);
            } else {
                setCompanies(user?.companies_details);
            }
        }
    }, [user, getAll, companies]);

    useEffect(() => {
        if (companies) {
            if (companies.length) {
                setCompany(companies[0]);
            } else {
                setShowModalForm(true);
            }
        }
    }, [companies]);

    useEffect(() => {
        if (company) {
            setItemsCheckList(company.items_check_list || [])
        }
    }, [company]);

    const removeCompany = useCallback(() => {
        if (
            window.confirm(`Are you sure you want to remove company ${company?.name}?`)
        ) {
            deleteItem("/companies", String(company?.id));
            setCompanies((x) => {
                const list = [...(x || [])];
                const idx = list.findIndex((y) => y.id === company?.id);
                if (idx > -1) {
                    list.splice(idx, 1);
                }
                return list;
            });
        }
    }, [company, deleteItem]);

    const handleOnItemChange = useCallback(({target}) => {
        setItemCheckListEdit(item => (item ? ({...item, item: target.value}) : undefined));
    }, []);

    const handleAddItem = useCallback(async () => {
        if (itemCheckListEdit) {
            const itemAdded = await post(`/items-check-list/${company?.id}/`, itemCheckListEdit);
            setItemsCheckList(x => [itemAdded, ...x]);
            setItemCheckListEdit(undefined);
        }
    }, [itemCheckListEdit, company, post]);

    const handeSaveOrEdit = useCallback(async (item) => {
        if (itemCheckListEdit) {
            const itemUpdated = await put(`/items-check-list/${company?.id}/`, itemCheckListEdit.id!, itemCheckListEdit);
            setItemsCheckList(x => {
                const list = [...x];
                const idx = list.findIndex(y => y.id === itemUpdated.id);
                list[idx] = itemUpdated;
                return list;
            });
            setItemCheckListEdit(undefined);
        } else {
            setItemCheckListEdit(item);
        }
    }, [itemCheckListEdit, company, put]);

    const handeRemoveOrClose = useCallback(async (item) => {
        if (!itemCheckListEdit) {
            if (window.confirm(`Are you sure you want to remove this item?`)) {
                await deleteItem(`/items-check-list/${company?.id}/`, item.id!);
                setItemsCheckList(x => {
                    const list = [...x];
                    const idx = list.findIndex(y => y.id === item.id);
                    list.splice(idx)
                    return list;
                });
                setItemCheckListEdit(undefined);
            }
        } else {
            setItemCheckListEdit(undefined);
        }
    }, [itemCheckListEdit, company, deleteItem]);

    return (
        <>
            <PageContainer>
                <Card>
                    <CardHeader title={"Company Config"}/>
                    <CardContent>
                        <Grid display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                            {!!companies?.length && (
                                <PopupState variant="popover">
                                    {(popupState) => (
                                        <>
                                            <Button
                                                size="large"
                                                variant="text"
                                                {...bindTrigger(popupState)}
                                            >
                                                {company?.name || "Select a company"}{" "}
                                                {companies?.length > 1 && <ArrowDropDownIcon/>}
                                            </Button>
                                            {companies?.length > 1 && (
                                                <Menu {...bindMenu(popupState)}>
                                                    {companies.map((x) => {
                                                        return (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    popupState.close();
                                                                    setCompany(x);
                                                                }}
                                                                key={x.id}
                                                            >
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Menu>
                                            )}
                                        </>
                                    )}
                                </PopupState>
                            )}
                            <Grid>
                                {user?.tipo === "ADMIN" && (
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                setCompanyForEdit(company);
                                                setShowModalForm(true);
                                            }}
                                        >
                                            <Edit/>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                removeCompany();
                                            }}
                                        >
                                            <Delete/>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setCompanyForEdit(undefined);
                                                setShowModalForm(true);
                                            }}
                                        >
                                            <Add/>
                                        </IconButton>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <br/>
                <Card>
                    <CardHeader title="Check-list items"/>
                    <CardContent>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setItemCheckListEdit({});
                            }}
                        >
                            <Add/> Add Item
                        </Button>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="100%" component='th'>
                                            Item
                                        </TableCell>
                                        <TableCell component='th'>
                                            Update/Edit
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {itemCheckListEdit && !itemCheckListEdit.id && <TableRow>
                                        <TableCell>
                                            <InputLabel>New item</InputLabel>
                                            <Input autoFocus type="text" onChange={handleOnItemChange}
                                                   placeholder="Type a new item" style={{width: "100%"}}/>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton disabled={!itemCheckListEdit?.item?.trim()}
                                                        onClick={handleAddItem}>
                                                <Save/>
                                            </IconButton>
                                            <IconButton color="error" onClick={() => setItemCheckListEdit(undefined)}>
                                                <Close/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>}
                                    {itemsCheckList.length > 0 && itemsCheckList.map(item => <TableRow key={item?.id}>
                                        <TableCell>
                                            {item.id === itemCheckListEdit?.id ?
                                                <Input autoFocus defaultValue={itemCheckListEdit?.item} type="text"
                                                       onChange={handleOnItemChange}
                                                       placeholder="Edit the item" style={{width: "100%"}}/> :
                                                <p>{item.item}</p>}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handeSaveOrEdit(item)}>
                                                {item.id === itemCheckListEdit?.id ? <Save/> : <Edit/>}
                                            </IconButton>
                                            <IconButton color="error" onClick={() => handeRemoveOrClose(item)}>
                                                {item.id === itemCheckListEdit?.id ? <Close/> : <DeleteOutline/>}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </PageContainer>
            <ModalFormCompany
                onClose={() => setShowModalForm(false)}
                onSave={(ret) =>
                    setCompanies((x) => {
                        const list = [...(x || [])];
                        const idx = list.findIndex((y) => y.id === ret.id);
                        if (idx > -1) {
                            list[idx] = ret;
                        } else {
                            list.push(ret);
                        }
                        return list;
                    })
                }
                open={showModalForm}
                company={companyForEdit}
            />
        </>
    );
}
