import {AlertColor, Snackbar} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Alert} from '@mui/lab';
import {AlertEvent, AlertEventType} from '../../app-events';

type AlertType = {
    key: number,
    alert: boolean,
    text: string | React.ReactNode,
    severity: AlertColor,
};

export function NotificationContainer() {
    const [alert, setAlert] = useState<AlertType[]>([]);
    const handleClose = (alert: AlertType) => {
        setAlert((s) => s.filter((x) => x.key !== alert.key));
    };

    const handleDangerAlert = (text: string) => {
        setAlert((s) => {
            return [
                ...s,
                {
                    key: new Date().getTime(),
                    text: text,
                    alert: true,
                    severity: 'error',
                },
            ];
        });
    };

    const handleInfoAlert = (text: string) => {
        setAlert((s) => {
            return [
                ...s,
                {
                    key: new Date().getTime(),
                    text: text,
                    alert: true,
                    severity: 'info',
                },
            ];
        });
    };

    const handleWarningAlert = (text: string) => {
        setAlert((s) => {
            return [
                ...s,
                {
                    key: new Date().getTime(),
                    text: text,
                    alert: true,
                    severity: 'warning',
                },
            ];
        });
    };

    const handleSuccessAlert = (text: string) => {
        setAlert((s) => {
            return [
                ...s,
                {
                    key: new Date().getTime(),
                    text: text,
                    alert: true,
                    severity: 'success',
                },
            ];
        });
    };

    useEffect(() => {
        AlertEvent.addListener(AlertEventType.DANGER, handleDangerAlert);
        AlertEvent.addListener(AlertEventType.INFO, handleInfoAlert);
        AlertEvent.addListener(AlertEventType.WARNING, handleWarningAlert);
        AlertEvent.addListener(AlertEventType.SUCCESS, handleSuccessAlert);
        return () => {
            AlertEvent.removeListener(AlertEventType.DANGER, handleDangerAlert);
            AlertEvent.removeListener(AlertEventType.INFO, handleInfoAlert);
            AlertEvent.removeListener(AlertEventType.WARNING, handleWarningAlert);
            AlertEvent.removeListener(AlertEventType.SUCCESS, handleSuccessAlert);
        };
    });

    return (
        <>
            {alert.map((a) => {
                return (
                    <Snackbar
                        key={a.key}
                        open={a.alert}
                        autoHideDuration={6000}
                        onClose={() => handleClose(a)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={() => handleClose(a)} severity={a.severity}>
                                {a.text}
                            </Alert>
                    </Snackbar>
                );
            })}
        </>
    );
}
