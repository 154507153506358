import * as React from "react";
import {PropsWithChildren} from "react";
import {styled, useTheme} from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Home from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import {Box} from "@mui/material";
import Colors from "../../constants/colors";
import {useHistory} from "react-router-dom";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import LogoutIcon from "@mui/icons-material/Logout";
import useUser from "../../hooks/useUser";
import useApi from "../../hooks/useApi";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const LayoutContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.grey100,
  minHeight: "100vh",
  width: "100%",
}));

export default function LayoutApp({
  children,
  menuDisabled,
  hideAppBar,
}: PropsWithChildren<{ menuDisabled?: boolean; hideAppBar?: boolean }>) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user, setUser } = useUser();
  const { deleteItem } = useApi();

  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onMenuClick = (address: string) => {
    history.push(address);
    handleDrawerClose();
  };

  return (
    <LayoutContainer>
      {!hideAppBar && (
        <AppBar position="fixed" open={open}>
          <Toolbar>
            {!menuDisabled && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap component="div">
              Mega Valet
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {!menuDisabled ? (
        <>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem button onClick={() => onMenuClick("/")}>
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>
              <ListItem button onClick={() => onMenuClick("/profile")}>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={"Profile"} />
              </ListItem>
              {(user?.tipo === "ADMIN" || user?.tipo === "COMPANY_ADMIN") && (
                <>
                  <ListItem button onClick={() => onMenuClick("/users")}>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Users"} />
                  </ListItem>
                  <ListItem button onClick={() => onMenuClick("/companies")}>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Companies"} />
                  </ListItem>
                </>
              )}
              <ListItem
                button
                onClick={async () => {
                  await deleteItem("/login", "0", { web: true });
                  handleDrawerClose();
                  setUser(undefined);
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItem>
            </List>
          </Drawer>
          <Main open={open}>{children}</Main>
        </>
      ) : (
        children
      )}
    </LayoutContainer>
  );
}
