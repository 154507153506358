import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {FieldJsonForm} from "../InputFormulario";
import {PropsWithChildren} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {IJsonFromProps} from "./types";

const defaultProps: IJsonFromProps = {
    onSubmit: () => {
    },
    onClick: () => {
    },
    textSubmitButton: "Submit",
    name: "",
    fields: [],
    textSecondaryButton: "Cancel",
    hasSecondaryButton: false,
};

export function Formulario(props: PropsWithChildren<IJsonFromProps>) {
    const localProps: IJsonFromProps = Object.assign(defaultProps, props);
    const formMethods = useForm({
        reValidateMode: "onSubmit",
        defaultValues: localProps.fields.reduce((previousValue, currentValue) => {
            previousValue[currentValue.name] = Array.isArray(
                currentValue.valueDefault
            )
                ? currentValue.valueDefault.map((x: any) => x?.value || x)
                : currentValue.valueDefault?.value || currentValue.valueDefault;
            return previousValue;
        }, {} as any),
    });

    let formElement: HTMLFormElement | null;

    const handleSubmit = (e: any) => {
        formMethods.clearErrors();
        return formMethods.handleSubmit((data: any) => {
            localProps.onSubmit(
                data,
                (erros) => {
                    if (erros) {
                        Object.keys(erros).forEach((key) => {
                            const erro = erros[key] || "";
                            if (Array.isArray(erro)) {
                                formMethods.setError(key, {
                                    types: erro.reduce((pre, pos, i) => {
                                        pre[`erro_${i}`] = pos;
                                        return pre;
                                    }, {} as any),
                                });
                            } else {
                                formMethods.setError(key, {
                                    message: erro as string,
                                    type: "required",
                                });
                            }
                        });
                    }
                },
                formElement
            );
        })(e);
    };

    return (
        <FormProvider {...formMethods} >
            <form autoComplete="off" name={localProps.name} onSubmit={handleSubmit}>
                <Box mb={2}>
                    {props.fields.map((field) => {
                        return (
                            <FieldJsonForm
                                key={field.name}
                                field={field}
                            />
                        );
                    })}
                </Box>
                {props.contentBeforeButton && (
                    <Box mb={2}>{props.contentBeforeButton}</Box>
                )}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Box component="p" fontSize={{xs: 12, sm: 16}}>
                        {props.children}
                    </Box>

                    <Box>
                        {props.hasSecondaryButton && (
                            <Button
                                style={{marginRight: 10}}
                                variant="contained"
                                color={props.colorSubmitButton || "primary"}
                                disabled={props.loadingSecondary || props.disabledSecondary}
                                onClick={props.onClick}
                            >
                                {props.loadingSecondary && (
                                    <CircularProgress color={"inherit"} size={"1rem"}/>
                                )}
                                {!props.loadingSecondary && localProps.textSecondaryButton}
                            </Button>
                        )}

                        <Button
                            type="submit"
                            variant="contained"
                            color={props.colorSubmitButton || "primary"}
                            disabled={props.loading || props.disabled}
                        >
                            {props.loading && (
                                <CircularProgress color={"inherit"} size={"1rem"}/>
                            )}
                            {!props.loading && localProps.textSubmitButton}
                        </Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
}
