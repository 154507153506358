import {EventEmitter} from 'events';

export const ApiEvent = new EventEmitter();
export const AlertEvent = new EventEmitter();

export enum AlertEventType {
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
    CHAT_MSG = 'chat-msg',
}
