import {makeStyles} from "@mui/styles";
import {alpha, Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {useEffect} from "react";
import * as queryString from "query-string";
import {useLocation} from "react-router-dom";
import {Formulario} from "../../components/Formulario";
import useUser from "../../hooks/useUser";
import useApi from "../../hooks/useApi";
import {OnSubmitType} from "../../components/Formulario/types";
import {IJsonFromField, TypeFieldForm,} from "../../components/InputFormulario/types";
import LogoMegaValet from "../../assets/images/logo-megavalet.png";
import {Theme} from "@mui/system";
import PageContainer from "../../components/PageContainer";

const formulario = [
  {
    name: "email",
    type: TypeFieldForm.TEXT,
    label: "E-mail",
    validators: {
      required: true,
    },
  },
  {
    name: "senha",
    type: TypeFieldForm.PASSWORD,
    label: "Password",
    validators: {
      required: true,
    },
  },
] as IJsonFromField[];

const useStyles = makeStyles<Theme>((theme) => ({
  authThumb: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    margin: "0 auto",
    marginTop: "100px",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 1,
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.primary.main,
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.black, 0.12),
    },
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
  logo: {
    width: "70%",
    margin: "auto",
  },
  card: {
    padding: 40,
    maxWidth: 600,
    margin: 'auto',
  },
}));

const LoginPage = (props: any) => {
  const { user, setUser } = useUser();
  const { variant = "default" } = props;
  const classes = useStyles({ variant });
  const location = useLocation();
  const { post } = useApi();
  useEffect(() => {
    const { goBack } = queryString.parse(location.search);
    if (user) {
      props.history.push(!goBack ? "/" : String(goBack));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const realizarLogin: OnSubmitType = async (data, errorCallback) => {
    post("/login", { ...data, web: true }).then(
      ({ usuario }) => {
        setUser(usuario);
      },
      (e) => {
        if (e.status === 400 && e.data && errorCallback) errorCallback(e.data);
      }
    );
  };

  return (
    <PageContainer>
      <Grid item xs={12} alignItems="center" justifyContent="center">
        <Box className={classes.authContent}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                component="div"
                variant="body1"
                align="center"
                className={classes.titleRoot}
              >
                <img src={LogoMegaValet} className={classes.logo} alt="logo" />
                <br />
                Enter your email address and password to login.
              </Typography>
              <Formulario
                colorSubmitButton="primary"
                name={"LoginLav"}
                fields={formulario}
                onSubmit={realizarLogin}
                textSubmitButton={"Sign In"}
              />
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default LoginPage;
