import {FormControl, InputLabel, MenuItem, Select,} from "@mui/material";
import moment from "moment";
import React, {CSSProperties, useCallback} from "react";
import Colors from "../../constants/colors";

type Props = {
    onChange: (value: Date) => void;
    value: Date;
    label?: string;
};
const HOURS = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
];
const MINUTES = ["00", "15", "30", "45"];

export default function SelectTimeWeb({value, onChange, label}: Props) {
    const hour = moment(value).format("hh");
    const minute = moment(value).format("mm");
    const amPm = moment(value).format("a");


    const setValue = useCallback((values: { h?: string; m?: string; a?: string }) => {
        const [dia] = new Date().toISOString().split("T");
        const a = values.a || amPm;
        const h = values.h || hour;
        const m = values.m || minute;
        onChange(
            moment(`${dia} ${h}:${m} ${a}`, "YYYY-MM-DD hh:mm a").toDate()
        );
    }, [hour, minute, amPm, onChange]);

    const selectbInput: CSSProperties = {
        height: 40,
        fontSize: 18,
        textAlign: "center",
        borderRadius: 5,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: "transparent",
        marginLeft: 6,
        marginRight: 6,
    };

    return (
        <FormControl
            style={{
                flexDirection: "row",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                border: "1px solid " + Colors.grey400,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 5,
                marginTop: 8,
                marginBottom: 16,
            }}
        >
            <InputLabel>{label}</InputLabel>

            <FormControl>
                <InputLabel id="hours">Hour</InputLabel>
                <Select
                    style={selectbInput}
                    labelId="hours"
                    id="hours"
                    value={hour}
                    label="Hour"
                    onChange={({target}) => setValue({h: target.value})}
                >
                    {HOURS.map((x) => {
                        return (
                            <MenuItem value={x} key={x}>
                                {x}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="minutes">Minute</InputLabel>
                <Select
                    style={selectbInput}
                    labelId="minutes"
                    id="minutes"
                    label="Minute"
                    value={minute}
                    onChange={({target}) => setValue({m: target.value})}
                >
                    {MINUTES.map((x) => {
                        return (
                            <MenuItem value={x} key={x}>
                                {x}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="AM/PM">AM/PM</InputLabel>
                <Select
                    style={selectbInput}
                    labelId="AM/PM"
                    id="AM/PM"
                    label="AM/PM"
                    value={amPm}
                    onChange={({target}) => setValue({a: target.value})}
                >
                    {["am", "pm"].map((x) => {
                        return (
                            <MenuItem value={x} key={x}>
                                {x.toUpperCase()}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </FormControl>
    );
}
