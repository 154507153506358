import {Box, Button, CardHeader, Grid, Hidden, Menu, MenuItem, TextField,} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {ICarWash} from "../../models/CarWash";
import useUser from "../../hooks/useUser";
import moment from "moment";
import {Loader} from "../../components/Loader";
import {CarWashTable} from "../../components/CarWashTable";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ModalFormFilter from "./ModalFormFilter";
import CompanyContext from "../../contexts/CompanyContext";
import useApi from "../../hooks/useApi";
import ModalFormCarWash from "./ModalFormCarWash";
import Search from "@mui/icons-material/Search";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import PageContainer from "../../components/PageContainer";

const HomePage = (props: any) => {
  const { getAll, put } = useApi();
  const { user } = useUser();
  const { company, companies, setCompany } = useContext(CompanyContext);
  const [filters, setFilters] = useState<any>({
    date: moment(new Date()).format("yyyy-MM-DD"),
    delivered: "No",
  });
  const [showModalCarWash, setShowModalCarWash] = useState(false);
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [applyingFilters, setApplyingFilters] = useState(false);
  const [washes, setWashes] = useState<ICarWash[]>();
  const [dashboard, setDashboard] = useState<any>();
  const [carWashToEdit, setCarWashToEdit] = useState<ICarWash>();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    (async () => {
      await buscarLista();
      setShowModalFilters(false);
      setApplyingFilters(false);
    })();
    const interval = setInterval(() => {
      buscarLista();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, company, companies, searchQuery]);

  const buscarLista = async () => {
    if (company || companies?.length === 0) {
      return getAll("/carwash", {
        ...filters,
        query: searchQuery,
        company: company?.id,
      }).then(({ list, dashboard }) => {
        setWashes(list);
        setDashboard(dashboard);
      });
    }
  };

  const changesCar = (car: ICarWash) => {
    setWashes((cars) => {
      const list = [...(cars || [])];
      const idx = list.findIndex((x) => x.id === car.id);
      if (idx > -1) {
        list[idx] = car;
      }
      return list;
    });
  };

  const sendCarChanges = (car: ICarWash) => {
    put("/carwash", String(car?.id) || "", car);
    changesCar(car);
  };

  const renderHeader = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="info"
              onClick={() => setShowModalFilters(true)}
            >
              <FilterAltIcon /> <Hidden only="xs"> Filter</Hidden>
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="center">
            {!!companies?.length && (
              <PopupState variant="popover">
                {(popupState) => (
                  <>
                    <Button
                      size="large"
                      variant="text"
                      {...bindTrigger(popupState)}
                    >
                      {company?.name || "Select a company"}{" "}
                      {companies?.length > 1 && <ArrowDropDownIcon />}
                    </Button>
                    {companies?.length > 1 && (
                      <Menu {...bindMenu(popupState)}>
                        {companies.map((x) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                setCompany(x);
                              }}
                              key={x.id}
                            >
                              {x.name}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    )}
                  </>
                )}
              </PopupState>
            )}
          </Grid>
          <Grid item xs={3} textAlign="right" marginLeft="auto">
            <Button
              variant="contained"
              onClick={() => {
                setCarWashToEdit(undefined);
                setShowModalCarWash(true);
              }}
            >
              <AddIcon />
              <Hidden only="xs"> New Vehicle</Hidden>
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <PageContainer>
      <Card>
        <CardContent>
          <CardHeader title={renderHeader()} disableTypography />
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label="Input search"
              variant="standard"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </Box>
          {washes && (
            <Box>
              <CarWashTable
                washes={washes}
                usuario={user}
                dashboard={dashboard}
                onEdit={(car) => {
                  setCarWashToEdit(car);
                  setShowModalCarWash(true);
                }}
                saveCar={sendCarChanges}
              />
            </Box>
          )}
          {!washes && (
            <Box>
              <Loader />
            </Box>
          )}
        </CardContent>
      </Card>
      <ModalFormCarWash
        open={showModalCarWash}
        onClose={() => setShowModalCarWash(false)}
        onSave={changesCar}
        wash={carWashToEdit}
      />
      <ModalFormFilter
        onApply={(data) => setFilters((f: any) => ({ ...f, ...data }))}
        onClose={() => setShowModalFilters(false)}
        open={showModalFilters}
        value={filters}
        applyingFilters={applyingFilters}
      />
    </PageContainer>
  );
};

export default HomePage;
