import {OptionType} from '../Select2';
import React from 'react';
import {RegisterOptions} from 'react-hook-form';

export enum TypeFieldForm {
    TEXT,
    SELECT,
    NUMBER,
    MULTI_SELECT,
    TEL,
    PASSWORD,
    EMAIL,
    DATE,
    TEXTAREA,
    DATE_TIME_LOCAL,
    HIDDEN,
    LOCALIZACAO,
    MOEDA,
    BOOLEAN,
    CPF,
    SINGLE_UPLOAD,
    UPLOAD,
    TIME,
}

export interface IJsonFromField {
    name: string;
    label: string;
    type: TypeFieldForm,
    valueDefault?: any;
    placeholder?: string;
    helpText?: string;
    mask?: any;
    disabled?: boolean | (() => boolean);
    readonly?: boolean;
    validators?: RegisterOptions | (() => RegisterOptions);
    choices?: (() => Promise<OptionType[]>) | OptionType[] | undefined;
    onChange?: (data: any, setValue?: (name: string, value: any) => void) => void;
    onBlur?: (data: any, setValue?: (name: string, value: any) => void) => void;
    filter?: (value: any) => any;
    preFilter?: (value: any) => any;
    visivel?: boolean;
    key?: any;
    acceptFiles?: string;
    extraFilter?: (onSelected: (selected: any) => void) => React.ReactNode;
    style?: React.CSSProperties;
    minDate?: Date;
    max?: number;
    min?: number;
}
