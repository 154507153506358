import {useState} from "react";
import {Formulario} from "../../components/Formulario";
import {IJsonFromField, TypeFieldForm,} from "../../components/InputFormulario/types";
import ModalApp from "../../components/ModalApp";
import useApi from "../../hooks/useApi";
import {Company} from "../../models/Company";

type Props = {
  open: boolean;
  onClose: () => void;
  company?: Company;
  onSave: (company: Company) => void;
};

const ModalFormCompany = ({ open, onClose, company, onSave }: Props) => {
  const [loading, setLoading] = useState(false);
  const { post, put } = useApi();

  const formulario: IJsonFromField[] = [
    {
      name: "name",
      type: TypeFieldForm.TEXT,
      label: "Name",
      valueDefault: company?.name,
      style: {
        backgroundColor: "transparent",
      },
      validators: {
        required: {
          value: true,
          message: "Name is required",
        },
        maxLength: 40,
      },
    },
  ];

  const handleSubmit = (data: any, errosCallback?: (erros: any) => void) => {
    setLoading(true);
    if (company?.id) {
      put("/companies", company?.id, data).then(
        async (ret) => {
          onSave(ret);
          onClose();
        },
        (e) => {
          setLoading(false);
          if (e.status === 400 && errosCallback) {
            errosCallback(e.data);
          }
        }
      );
    } else {
      post("/companies", data).then(
        async (ret) => {
          onSave(ret);
          onClose();
        },
        (e) => {
          setLoading(false);
          if (e.status === 400 && errosCallback) {
            errosCallback(e.data);
          }
        }
      );
    }
  };

  return (
    <ModalApp open={open} onClose={onClose} title={company ? "Edit Company" : "Add Company"}>
      <Formulario
        name={company ? "Edit Company" : "Add Company"}
        loading={loading}
        fields={formulario}
        onSubmit={handleSubmit}
        textSubmitButton={"apply"}
      />
    </ModalApp>
  );
};

export default ModalFormCompany;
