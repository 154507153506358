import moment from "moment";
import {useContext, useState} from "react";
import {Formulario} from "../../components/Formulario";
import {TypeFieldForm} from "../../components/InputFormulario/types";
import ModalApp from "../../components/ModalApp";
import CompanyContext from "../../contexts/CompanyContext";
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import {ICarWash} from "../../models/CarWash";

type Props = {
    open: boolean;
    onClose: () => void;
    wash?: ICarWash;
    onSave: (wash: ICarWash) => void;
};

const ModalFormCarWash = ({open, onClose, wash, onSave}: Props) => {
    const [loading, setLoading] = useState(false);
    const {user: usuario} = useUser();
    const {post, put} = useApi();
    const {company} = useContext(CompanyContext);

    const handleSubmit = (data: any, errosCallback?: (erros: any) => void) => {
        setLoading(true);
        const [, promTime] = moment(data.prom_time).format().split("T");
        const [date] = moment(data.date).format().split("T");
        post("/carwash", {
            ...data,
            inDetail: data.inDetail === "true",
            delivered: data.delivered === "true",
            company: company?.id,
            prom_time: `${date}T${promTime}`,
            date: undefined
        }).then(
            (ret) => {
                setLoading(false);
                onSave(ret);
                onClose();
            },
            (e) => {
                setLoading(false);
                if (e.status === 400 && errosCallback) {
                    const erros = e.data;
                    errosCallback(erros);
                }
            }
        );
    };

    const handlePutSubmit = (data: any, errosCallback?: (erros: any) => void) => {
        setLoading(true);
        const [, promTime] = moment(data.prom_time).format().split("T");
        const [date] = moment(data.date).format().split("T");
        put("/carwash", String(wash?.id) || "", {
            ...data,
            inDetail: data.inDetail === "true",
            delivered: data.delivered === "true",
            company: company?.id,
            prom_time: `${date}T${promTime}`,
            date: undefined
        }).then(
            (ret) => {
                setLoading(false);
                onSave(ret);
                onClose();
            },
            (e) => {
                setLoading(false);
                if (e.status === 400 && errosCallback) {
                    const erros = e.data;
                    errosCallback(erros);
                }
            }
        );
    };
    return (
        <ModalApp
            open={open}
            onClose={onClose}
            title={wash ? `TAG #${wash?.tag}` : "Add Car"}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Formulario
                loading={loading}
                name={"FormCarWash"}
                textSubmitButton="Save"
                fields={[
                    {
                        name: "tag",
                        type: TypeFieldForm.TEXT,
                        label: "TAG",
                        valueDefault: wash?.tag,
                        style: {
                            backgroundColor: "transparent",
                        },
                        disabled: () =>
                            usuario?.tipo === "LAVADOR" || usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "color",
                        type: TypeFieldForm.TEXT,
                        label: "Color",
                        valueDefault: wash?.color,
                        style: {
                            backgroundColor: "transparent",
                        },
                        disabled: () =>
                            usuario?.tipo === "LAVADOR" || usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "vehicle",
                        type: TypeFieldForm.TEXT,
                        label: "Vehicle",
                        valueDefault: wash?.vehicle,
                        style: {
                            backgroundColor: "transparent",
                        },
                        disabled: () => usuario?.tipo === "LAVADOR",
                    },
                    {
                        name: "note",
                        type: TypeFieldForm.TEXT,
                        label: "Note",
                        valueDefault: wash?.note,
                        style: {
                            backgroundColor: "transparent",
                        },
                        disabled: () =>
                            usuario?.tipo === "LAVADOR" || usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "date",
                        type: TypeFieldForm.DATE,
                        label: "Date",
                        style: {
                            backgroundColor: "transparent",
                        },
                        valueDefault: moment(wash?.prom_time).toDate(),
                        disabled: () =>
                            usuario?.tipo === "LAVADOR" || usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "prom_time",
                        type: TypeFieldForm.TIME,
                        label: "Prom. Time",
                        style: {
                            backgroundColor: "transparent",
                        },
                        valueDefault: wash?.prom_time
                            ? moment(wash?.prom_time).toDate()
                            : moment().minutes(0).toDate(),
                        validators: {
                            required: {
                                value: true,
                                message: "Is required",
                            },
                        },
                        disabled: () =>
                            usuario?.tipo === "LAVADOR" || usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "priority",
                        type: TypeFieldForm.SELECT,
                        label: "Priority",
                        style: {
                            backgroundColor: "transparent",
                        },
                        validators: {
                            required: {
                                value: true,
                                message: "Is required",
                            },
                        },
                        valueDefault: {
                            value: wash?.priority ? wash.priority : "Wash and Stage",
                            label: wash?.priority ? wash.priority : "Wash and Stage",
                        },
                        choices: [
                            "Wash and Stage",
                            "Pull up to Driveway",
                            "Customer is Waiting",
                            "Park In Customer Parking",
                        ].map((x) => {
                            return {
                                value: x,
                                label: x,
                            };
                        }),
                        disabled: () => usuario?.tipo === "LAVADOR",
                    },
                    {
                        name: "status",
                        type: TypeFieldForm.SELECT,
                        label: "Status",
                        style: {
                            backgroundColor: "transparent",
                        },
                        valueDefault: {
                            value: wash?.status ? wash.status : "Not Washed",
                            label: wash?.status ? wash.status : "Not Washed",
                        },
                        choices: [
                            "Washed",
                            "Not Washed",
                            "Queued",
                            "Not in Carwash",
                            "Wash in process",
                        ].map((x) => {
                            return {
                                value: x,
                                label: x,
                            };
                        }),
                        validators: {
                            required: {
                                value: true,
                                message: "Is required",
                            },
                        },
                        disabled: () => usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "in_detail",
                        type: TypeFieldForm.SELECT,
                        label: "In Detail",
                        style: {
                            backgroundColor: "transparent",
                        },
                        valueDefault:
                            wash?.in_detail === undefined
                                ? {
                                    value: "false",
                                    label: "No",
                                }
                                : {
                                    value: String(wash?.in_detail),
                                    label: wash?.in_detail ? "Yes" : "No",
                                },
                        choices: [
                            {
                                value: "true",
                                label: "Yes",
                            },
                            {
                                value: "false",
                                label: "No",
                            },
                        ],
                        validators: {
                            required: {
                                value: true,
                                message: "Is required",
                            },
                        },
                        disabled: () => usuario?.tipo === "MANOBRISTA",
                    },
                    {
                        name: "delivered",
                        type: TypeFieldForm.SELECT,
                        label: "Delivered",
                        style: {
                            backgroundColor: "transparent",
                        },
                        valueDefault:
                            wash?.delivered === undefined
                                ? {
                                    value: "false",
                                    label: "No",
                                }
                                : {
                                    value: String(wash?.delivered),
                                    label: wash?.delivered ? "Yes" : "No",
                                },
                        choices: [
                            {
                                value: "true",
                                label: "Yes",
                            },
                            {
                                value: "false",
                                label: "No",
                            },
                        ],
                        validators: {
                            required: {
                                value: true,
                                message: "Is required",
                            },
                        },
                        disabled: () => usuario?.tipo === "LAVADOR",
                    },
                ]}
                onSubmit={wash ? handlePutSubmit : handleSubmit}
            />
        </ModalApp>
    );
};

export default ModalFormCarWash;
