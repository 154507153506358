import {ErrorMessage} from "@hookform/error-message";
import {Container, FormHelperText, Grid, IconButton, Switch,} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import {makeStyles} from "@mui/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {IJsonFromField, TypeFieldForm} from "../InputFormulario/types";
import Select2, {OptionType} from "../Select2";
import moment from "moment";
import {useCallback, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import TrashIcon from "@mui/icons-material/Delete";
import DatePicker from '@mui/lab/DatePicker';
import SelectTimeWeb from "../SelectTimeWeb";
import {useController} from "react-hook-form";

const useStyles = makeStyles(() => ({
    boxDiv: {
        width: "100%",
    },
    input: {
        width: "100%",
    },
    select2: {
        width: "100%",
        marginTop: "16px",
        marginBottom: "8px",
    },
}));

const styles = {
    baseDaDStyle: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#ff005f",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    },
    activeDaDStyle: {
        borderColor: "#2196f3",
    },
    acceptDaDStyle: {
        borderColor: "#00e676",
    },
    rejectDaDStyle: {
        borderColor: "#ff1744",
    },
};

export function FieldJsonForm(props: {
    field: IJsonFromField;
}) {
    const classes = useStyles();
    const [valueSelect, setValueSelect] = useState<
        OptionType[] | OptionType | null
    >(props.field.valueDefault || null);
    const [valueBoolean, setValueBoolean] = useState<boolean>(
        !!props.field.valueDefault
    );
    const [valueUpload, setValueUpload] = useState<File[]>(
        props.field.valueDefault || []
    );
    const {field} = props;
    const {
        field: inputField,
        formState: {errors},
    } = useController({name: field.name});

    const {value, onChange} = inputField;

    const handleOnChange = useCallback((value) => {
        if (onChange) {
            onChange(value);
        }
        if (props.field.onChange) {
            props.field.onChange(value);
        }
    }, [onChange, props.field.onChange]);

    const disabled =
        field.disabled && typeof field.disabled === "function"
            ? field.disabled()
            : field.disabled;

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles) {
            if (field.type === TypeFieldForm.SINGLE_UPLOAD && acceptedFiles[0]) {
                handleOnChange(acceptedFiles[0]);
                setValueUpload(acceptedFiles[0]);
            } else {
                const validFiles = acceptedFiles.filter((x: any) => !!x);
                handleOnChange([...valueUpload, ...validFiles]);
                setValueUpload((x) => {
                    return [...x, ...validFiles];
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: field.acceptFiles,
    });
    const styleDaD = useMemo(
        () => ({
            ...styles.baseDaDStyle,
            ...(isDragActive ? styles.activeDaDStyle : {}),
            ...(isDragAccept ? styles.acceptDaDStyle : {}),
            ...(isDragReject ? styles.rejectDaDStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const extraComponente = () => {
        return (
            <>
                {field.helpText ? (
                    <FormHelperText>{field.helpText}</FormHelperText>
                ) : (
                    ""
                )}
                {errors && (
                    <ErrorMessage
                        errors={errors}
                        name={field.name}
                        render={({message, messages}) => {
                            if (message) {
                                return (
                                    <Typography variant="caption" color={"error"}>
                                        {message}
                                    </Typography>
                                );
                            }
                            if (messages) {
                                return Object.keys(messages).map((x) => (
                                    <Typography key={x} variant="caption" color={"error"}>
                                        {messages[x]}
                                        <br/>
                                    </Typography>
                                ));
                            }
                            return <div/>;
                        }}
                    />
                )}
            </>
        );
    };
    const isInvalid = !!errors?.[field.name];
    if (field.visivel === false) {
        return <></>;
    }
    switch (field.type) {
        case TypeFieldForm.HIDDEN:
            return <input type="hidden" {...inputField} />;
        case TypeFieldForm.DATE_TIME_LOCAL:
        case TypeFieldForm.DATE:
            let dataValue = value || props.field.valueDefault;
            dataValue = moment(dataValue, "YYYY-MM-DD");
            if (!dataValue.isValid()) {
                dataValue = null;
            }
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <DatePicker
                        label={field.label}
                        minDate={field.minDate ? field.minDate : moment(new Date("1900-01-01"))}
                        onChange={(inputEvent) => {
                            handleOnChange(inputEvent?.format("YYYY-MM-DD"));
                        }}
                        value={dataValue}
                        renderInput={(params) => (
                            <TextField {...params} className={classes.input}/>
                        )}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.TIME:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <SelectTimeWeb
                        value={value || props.field.valueDefault}
                        onChange={(values) => {
                            handleOnChange(values);
                        }}
                        label={field.label}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.MOEDA:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <NumberFormat
                        style={field.style}
                        customInput={TextField}
                        thousandSeparator={"."}
                        decimalScale={2}
                        allowNegative
                        allowedDecimalSeparators={[","]}
                        decimalSeparator={","}
                        allowEmptyFormatting
                        fixedDecimalScale
                        prefix={"R$ "}
                        type="tel"
                        autoComplete="off"
                        label={field.label}
                        InputProps={{
                            readOnly: field.readonly,
                            className: classes.input,
                        }}
                        name={field.name}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={Number(field.valueDefault)}
                        fullWidth
                        margin="normal"
                        error={isInvalid}
                        value={Number(value || "0")}
                        onValueChange={(values: any) => {
                            const {floatValue} = values;
                            handleOnChange(floatValue);
                        }}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.EMAIL:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <TextField
                        style={field.style}
                        autoComplete="off"
                        label={field.label}
                        InputProps={{
                            readOnly: field.readonly,
                            inputProps: {min: field.min, max: field.max},
                            className: classes.input,
                            ...inputField,
                        }}
                        name={field.name}
                        type={"email"}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        fullWidth
                        margin="normal"
                        error={isInvalid}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.NUMBER:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <TextField
                        style={field.style}
                        autoComplete="off"
                        label={field.label}
                        InputProps={{
                            readOnly: field.readonly,
                            inputProps: {min: field.min, max: field.max},
                            className: classes.input,
                            ...inputField
                        }}
                        name={field.name}
                        type={"number"}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        fullWidth
                        margin="normal"
                        error={isInvalid}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.TEL:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <InputMask
                        mask="(99) 9999-99999"
                        disabled={!!disabled}
                        name={field.name}
                        defaultValue={field.valueDefault}
                        onChange={(event: any) =>
                            handleOnChange(event.target.value)
                        }
                        value={value}
                    >
                        {(inputProps: any) => (
                            <TextField
                                {...inputProps}
                                autoComplete="off"
                                label={field.label}
                                InputProps={{
                                    readOnly: field.readonly,
                                }}
                                type={field.type}
                                placeholder={field.placeholder}
                                fullWidth
                                margin="normal"
                                className={classes.input}
                                disableUnderline
                                error={isInvalid}
                                style={field.style}
                            />
                        )}
                    </InputMask>
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.TEXT:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <TextField
                        label={field.label}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly,
                            ...inputField,
                            className: classes.input,
                        }}
                        name={field.name}
                        type={"text"}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        fullWidth
                        margin="normal"
                        error={isInvalid}
                        style={field.style}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.PASSWORD:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <TextField
                        label={field.label}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly,
                            ...inputField,
                            className: classes.input,
                        }}
                        name={field.name}
                        type={"password"}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        fullWidth
                        margin="normal"
                        error={isInvalid}
                        style={field.style}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.TEXTAREA:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <TextField
                        label={field.label}
                        disabled={!!disabled}
                        name={field.name}
                        placeholder={field.placeholder}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly,
                            className: classes.input,
                            ...inputField,
                        }}
                        error={isInvalid}
                        fullWidth
                        margin="normal"
                        style={field.style}
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.SELECT:
        case TypeFieldForm.MULTI_SELECT:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <Select2
                        label={field.label}
                        fullWidth
                        className={classes.select2}
                        onChange={(selected) => {
                            setValueSelect(selected);
                            if (Array.isArray(selected)) {
                                handleOnChange(
                                    selected.map((x) => x.value)
                                );
                            } else {
                                handleOnChange(selected?.value || null);
                            }
                        }}
                        multiple={field.type === TypeFieldForm.MULTI_SELECT}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        error={isInvalid}
                        options={props.field.choices ? props.field.choices : []}
                        value={
                            field.type === TypeFieldForm.MULTI_SELECT && !valueSelect
                                ? []
                                : valueSelect
                        }
                    />
                    {extraComponente()}
                </Box>
            );
        case TypeFieldForm.SINGLE_UPLOAD:
        case TypeFieldForm.UPLOAD:
            return (
                <Box component={"div"} className={classes.boxDiv}>
                    <InputLabel
                        style={{
                            textAlign: "left",
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        {field.label}
                    </InputLabel>
                    <div {...getRootProps({style: styleDaD as any})}>
                        <input {...getInputProps()} disabled={disabled}/>
                        <p>{`Arraste e solte ${
                            field.type === TypeFieldForm.SINGLE_UPLOAD
                                ? "seu arquivo"
                                : "seus arquivos"
                        }, ou clique para selecionar`}</p>
                    </div>
                    <br/>
                    {valueUpload.map((x) => {
                        return (
                            <Box
                                component={"div"}
                                className={classes.boxDiv}
                                style={{
                                    marginBottom: 5,
                                }}
                                key={String(x.name) + String(x.size)}
                            >
                                <Grid
                                    container
                                    alignItems={"center"}
                                    style={{
                                        height: 55,
                                    }}
                                >
                                    <Grid xs={10} item>
                                        <Typography align={"left"}>{x.name}</Typography>
                                    </Grid>
                                    <Grid xs={2} item>
                                        <IconButton
                                            color={"secondary"}
                                            onClick={() => {
                                                setValueUpload((arqs) => {
                                                    if (field.type === TypeFieldForm.SINGLE_UPLOAD) {
                                                        handleOnChange(null);
                                                        return [];
                                                    } else {
                                                        const news = [...arqs.filter((y) => y !== x)];
                                                        handleOnChange(news);
                                                        return news;
                                                    }
                                                });
                                            }}
                                        >
                                            <TrashIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <br/>
                            </Box>
                        );
                    })}
                    {extraComponente()}
                    <hr/>
                </Box>
            );
        case TypeFieldForm.BOOLEAN:
            return (
                <Box
                    component={"div"}
                    className={classes.boxDiv}
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    <Container component="div">
                        <Grid item xs={9} md={10}>
                            <InputLabel>{field.label}</InputLabel>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            md={2}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                            component="div"
                        >
                            <Switch
                                disabled={disabled}
                                checked={valueBoolean}
                                onChange={(v, checked) => {
                                    setValueBoolean(checked);
                                    handleOnChange(checked);
                                }}
                                color="secondary"
                                inputProps={{"aria-label": "secondary checkbox"}}
                            />
                        </Grid>
                    </Container>
                    {extraComponente()}
                    <hr/>
                </Box>
            );
        default:
            return <div/>;
    }
}
