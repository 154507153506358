import {Formulario} from "../../components/Formulario";
import {TypeFieldForm} from "../../components/InputFormulario/types";
import ModalApp from "../../components/ModalApp";

type Props = {
  open: boolean;
  onClose: () => void;
  applyingFilters?: boolean;
  value: any;
  onApply: (filter: any) => void;
};

const ModalFormFilter = ({
  open,
  onClose,
  applyingFilters,
  value,
  onApply,
}: Props) => {
  return (
    <ModalApp open={open} onClose={onClose} title={"Filters"}>
      <Formulario
        name={"Filtres"}
        loading={applyingFilters}
        fields={[
          {
            label: "Date",
            type: TypeFieldForm.DATE,
            name: "date",
            valueDefault: value?.date,
          },
          {
            label: "Status",
            type: TypeFieldForm.SELECT,
            name: "status",
            valueDefault: value?.status
              ? {
                  value: value.status,
                  label: value.status,
                }
              : {
                  value: "all",
                  label: "All",
                },
            choices: [
              null,
              "Washed",
              "Not Washed",
              "Queued",
              "Not in Carwash",
              "Wash in process",
            ].map((x) => {
              return {
                label: x || "All",
                value: x || "all",
              };
            }),
          },
          {
            label: "Priority",
            type: TypeFieldForm.SELECT,
            name: "priority",
            valueDefault: value?.priority
              ? {
                  value: value.priority,
                  label: value.priority,
                }
              : {
                  value: "all",
                  label: "All",
                },
            choices: [
              null,
              "Wash and Stage",
              "Pull up to Driveway",
              "Customer is Waiting",
              "Park In Customer Parking",
            ].map((x) => {
              return {
                label: x || "All",
                value: x || "all",
              };
            }),
          },
          {
            label: "Delivered",
            type: TypeFieldForm.SELECT,
            name: "delivered",
            valueDefault: value?.delivered
              ? {
                  value: value.delivered,
                  label: value.delivered,
                }
              : {
                  value: "all",
                  label: "All",
                },
            choices: [null, "Yes", "No"].map((x) => {
              return {
                label: x || "All",
                value: x || "all",
              };
            }),
          },
        ]}
        onSubmit={(data) => {
          if (data.priority === "all") {
            data.priority = undefined;
          }
          if (data.status === "all") {
            data.status = undefined;
          }
          if (data.delivered === "all") {
            data.delivered = undefined;
          }
          onApply(data);
        }}
        textSubmitButton={"apply"}
      />
    </ModalApp>
  );
};

export default ModalFormFilter;
