import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {Redirect, Route, Switch, useLocation, withRouter,} from "react-router-dom";
import {AlertEvent, AlertEventType, ApiEvent} from "../app-events";
import {Loader} from "../components/Loader";
import LoginPage from "./LoginPage";
import LayoutApp from "../components/layout";
import useUser from "../hooks/useUser";
import useApi, {ApiEventsType} from "../hooks/useApi";
import HomePage from "./HomePage";
import ProfilePage from "./ProfilePage";
import UsersPage from "./UsersPage";
import CompaniesPage from "./CompaniesPage";

const RoutesPages = (props: any) => {
  const PATH_LOGIN = "/login";
  const { user, setUser } = useUser();
  const { get } = useApi();
  const [loading, setLoading] = useState(true);
  const { match } = props;
  const location = useLocation();

  useEffect(() => {
    if (!user && location.pathname !== PATH_LOGIN) {
      get("/sessao").then(setUser, () => {
        props.history.push(PATH_LOGIN + "?goBack=" + location.pathname);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const apiHandler = (error: any) => {
    if (error.status === 403) {
      setUser(undefined);
      return props.history.push(PATH_LOGIN + "?goBack=" + location.pathname);
    }
    if (error.status === 500) {
      AlertEvent.emit(AlertEventType.DANGER, "Try again later.");
    }
  };

  useEffect(() => {
    ApiEvent.addListener(ApiEventsType.HTTP_ERROR, apiHandler);
    return () => {
      ApiEvent.removeListener(ApiEventsType.HTTP_ERROR, apiHandler);
    };
  });
  console.log("match.url", match.url);

  if (location.pathname === PATH_LOGIN && !!user) {
    return <Redirect to={`${match.url}`} />;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        width={1}
        style={{ minHeight: "100vh" }}
      >
        <Loader />
      </Box>
    );
  }

  const isAdmin = user?.tipo === "ADMIN" || user?.tipo === "COMPANY_ADMIN";

  return (
    <LayoutApp hideAppBar={!user} menuDisabled={!user}>
      <Switch>
        {!user && (
          <Route
            path={`${match.url}login`}
            render={(props) => {
              return <LoginPage {...props} basePath={match} />;
            }}
          />
        )}
        {isAdmin && (
          <Route
            path={`${match.url}companies`}
            render={(props) => {
              return <CompaniesPage {...props} basePath={match} />;
            }}
          />
        )}
        {isAdmin && (
          <Route
            path={`${match.url}users`}
            render={(props) => {
              return <UsersPage {...props} basePath={match} />;
            }}
          />
        )}
        <Route
          path={`${match.url}profile`}
          render={(props) => {
            return <ProfilePage {...props} basePath={match} />;
          }}
        />
        <Route
          path={`${match.url}`}
          render={(props) => {
            return <HomePage {...props} basePath={match} />;
          }}
        />
      </Switch>
    </LayoutApp>
  );
};

export default withRouter(RoutesPages);
