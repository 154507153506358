import {Card, CardContent, CardHeader, Snackbar,} from "@mui/material";
import {useState} from "react";
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import {Formulario} from "../../components/Formulario";
import {TypeFieldForm} from "../../components/InputFormulario/types";
import PageContainer from "../../components/PageContainer";

export default function ProfilePage(props: any) {
  const { user: usuario, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { put } = useApi();

  const handleSubmit = (data: any, errosCallback?: (erros: any) => void) => {
    setLoading(true);
    put("/perfil", String(usuario?.id), data).then(
      async (ret) => {
        console.log(ret);
        setUser(ret);
        setLoading(false);
        setShowSnackbar(true);
      },
      (e) => {
        setLoading(false);
        if (e.status === 400 && errosCallback) {
          errosCallback(e.data);
        }
      }
    );
  };

  const handleSubmitPassword = (
    data: any,
    errosCallback?: (erros: any) => void
  ) => {
    setLoading(true);
    put("/perfil/senha-usuario", String(usuario?.id), data).then(
      async (ret) => {
        setLoading(false);
        setShowSnackbar(true);
      },
      (e) => {
        setLoading(false);
        if (e.status === 400 && errosCallback) {
          errosCallback(e.data);
        }
      }
    );
  };
  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="The data have been saved!"
      />
      <PageContainer>
        <Card>
          <CardHeader title={"Update Profile"} />
          <CardContent>
            <Formulario
              name={"Profile"}
              loading={loading}
              fields={[
                {
                  name: "first_name",
                  type: TypeFieldForm.TEXT,
                  label: "First Name",
                  valueDefault: usuario?.first_name,
                  style: {
                    backgroundColor: "transparent",
                  },
                  validators: {
                    required: {
                      value: true,
                      message: "First Name is required",
                    },
                    maxLength: 40,
                  },
                },
                {
                  name: "last_name",
                  type: TypeFieldForm.TEXT,
                  label: "Last Name",
                  valueDefault: usuario?.last_name,
                  style: {
                    backgroundColor: "transparent",
                  },
                  validators: {
                    maxLength: 40,
                  },
                },
                {
                  name: "email",
                  type: TypeFieldForm.EMAIL,
                  label: "Email",
                  valueDefault: usuario?.email,
                  style: {
                    backgroundColor: "transparent",
                  },
                  validators: {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  },
                },
              ]}
              onSubmit={handleSubmit}
              textSubmitButton={"apply"}
            />
          </CardContent>
        </Card>
        <br />
        <br />
        <Card>
          <CardHeader title={"Update Password"} />
          <CardContent>
            <Formulario
              name={"Password"}
              loading={loading}
              fields={[
                {
                  name: "senha",
                  type: TypeFieldForm.PASSWORD,
                  label: "Current Password",
                  style: {
                    backgroundColor: "transparent",
                  },
                  validators: {
                    required: {
                      value: true,
                      message: "It is required",
                    },
                  },
                },
                {
                  name: "nova_senha",
                  type: TypeFieldForm.PASSWORD,
                  label: "New Password",
                  style: {
                    backgroundColor: "transparent",
                  },
                  validators: {
                    required: {
                      value: true,
                      message: "It is required",
                    },
                  },
                },
                {
                  name: "confirme_senha",
                  type: TypeFieldForm.PASSWORD,
                  label: "Confirm Password",
                  style: {
                    backgroundColor: "transparent",
                  },
                  validators: {
                    required: {
                      value: true,
                      message: "It is required",
                    },
                  },
                },
              ]}
              onSubmit={handleSubmitPassword}
              textSubmitButton={"Update Password"}
            />
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
}
